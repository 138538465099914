<!-- @component
    Atom: builds the articles displayed in the SectionNewsInsights.svelte molecule.
 -->

<script lang="ts">
	// types
	import type { NewsArticle } from "$types/types";

	// props
	export let article: NewsArticle;
	export let headlineTag = "h3";
</script>

<template lang="pug">
	svelte:options(immutable=true)
	a.group.rounded-xl(
		href!="/news/{ article.slug }",
		title!="read article"
		class=`
			bg-mintCream
			block
			cursor-pointer
			outline-white
			outline-offset-8
			overflow-hidden
			pb-10
			relative
			transition-shadow
			shadow-night/10
			shadow-xl
			w-full
			hover:shadow-3xl
			hover:shadow-night/30
			hover:bg-white
			focus:shadow-3xl
			focus:shadow-night/30
			focus:bg-white
			`,
	)
		//- article image
		+if('article.image')
			img.w-full.h-auto.block.select-none(
				alt!="{ article.image.alt }",
				aria-hidden="true",
				draggable="false",
				height!="{ article.image.height }",
				loading="lazy",
				src!="{ article.image.src }",
				width!="{ article.image.width }"
			)


		//- metadata row
		.mb-4.opacity-80.pt-4.flex.px-6.font-light(
			class=`
				flex
				font-light
				mb-4
				opacity-80
				pt-4
				px-6
				text-[.965em]
				group-hover:opacity-100
				`
			)
			| { article.category }
			| &nbsp;-&nbsp;
			span(class="text-[.925em] tracking-wide") { article.formattedDate ?? article.date }

		svelte:element(
		this!="{headlineTag}"
		id!="headline-{ article.id }",
		class=`
		font-medium
		leading-snug
		opacity-90
		px-6
		text-[1.05em]
		hover:opacity-100
		`
			) {article.headline}
</template>
